.portfolio{
  // background: $black;
  margin-top: 150px;
  @media only screen and (max-width: $mobile){
    margin-top: 100px;
  }
  
  color: $white;
  &__about-section{
    margin-top: 15px;
    p{
      opacity: .85;
      @media only screen and (max-width: $tablet){
        margin-top: 38px;
      }
    }
  }
  &__tabs{
    margin-top: 60px;
    @media only screen and (max-width: $tablet){
      margin-top: 38px;
    }
    &__nav{
      
      border-bottom: none;
      @media only screen and (max-width: $tablet){
        flex-wrap: nowrap;
        overflow-y: auto;
      }
    }
    &__tab.nav-link{
      color: $white;
      border-radius: 0;
      border: none;
      background: none;
      border-bottom: 1px solid transparent;
      margin-bottom: 1px;
      &:hover{
        border-radius: 0;
        border-color: $primary;
      }
      &.active{
        background: none;
        color: $white;
        border-radius: 0;
        border-color: $primary;
      }
      @media only screen and (max-width: $mobile){
        font-size: 14px;
        white-space: nowrap;
      }
    }

    &__content{
      &__box{
        position: relative;
        overflow: hidden;
        // margin-top: 30px;
        height: 100%;
        img{
          height: 100%;
          object-fit: cover;
        }
        &__text{
          background-color: $black;
          position: absolute;
          // height: 100px;
          bottom: -100%;
          width: 90%;
          left: 5%;
          padding: 18px 20px;
          opacity: 0;
          transition: all .3s ease-in-out;
          &:hover path{
            fill: $primary !important;
          }

          .button{
            margin-top: 5px;
          }
        }
        &:hover &__text,
        &:focus &__text,
        &:focus-visible &__text{
          bottom: 10px;
          opacity: 1;
        }
        
      }
    }
  }
}